import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Carousel, Flex, Button, Badge } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardListCheck } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import { role } from "../../../providers/companyInfo";
import carouselImg from "../../../assets/img/survey.png";
import DashboardContext from "./DashboardContext";
import SurveyList from "./SurveyList";
import {
	checkPermissions,
	deleteFile,
	readFile,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";

export default function PageDashboard() {
	const navigate = useNavigate();

	const [surveyPendingCount, setSurveyPendingCount] = useState(0);
	const [dataSource, setDataSource] = useState([]);

	GET(
		`api/mobile_form?from=${
			role() === "Student" ? "SurveyMobileStudent" : "SurveyMobileFaculty"
		}`,
		"mobile_form_list",
		(res) => {
			let datasync = document.getElementById("datasync");
			if (datasync) {
				datasync.classList.remove("hide");
			}

			let data = {
				forms: res.data,
				faculties: res.dataFaculty,
			};

			setDataSource(data);

			checkPermissions().then((res1) => {
				if (res1) {
					if (res1.publicStorage === "granted") {
						setFileStorage("dsac_fsuu_evaluation", "db", data).then((res2) => {
							if (!res2 && !res2.uri) {
								deleteFile("dsac_fsuu_evaluation/db.txt").then((res3) => {
									if (!res3) {
										setFileStorage("dsac_fsuu_evaluation", "db", data).then(
											(res4) => {
												if (res2 && res2.uri) {
													setTimeout(() => {
														if (datasync) {
															datasync.classList.add("hide");
														}
													}, 1000);
												}
											}
										);
									}
								});
							} else {
								setTimeout(() => {
									if (datasync) {
										datasync.classList.add("hide");
									}
								}, 1000);
							}
						});
					}
				}
			});
		},
		false
	);

	useEffect(() => {
		checkPermissions().then((res1) => {
			if (res1) {
				if (res1.publicStorage === "granted") {
					readFile("dsac_fsuu_evaluation/db.txt").then((res2) => {
						if (res2 && res2.data) {
							res2 = JSON.parse(res2.data);
							setDataSource(res2);
						}
					});

					readFile("dsac_fsuu_evaluation/db_pending_list.txt").then((res2) => {
						if (res2 && res2.data) {
							res2 = JSON.parse(res2.data);
							setSurveyPendingCount(res2.length);
						}
					});
				}
			}
		});

		return () => {};
	}, []);

	return (
		<DashboardContext.Provider value={{ dataSource }}>
			<Row gutter={[20, 20]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
					<Carousel autoplay className="intro">
						<div className="carousel-item-home">
							<img src={carouselImg} alt="" />
						</div>
					</Carousel>
				</Col>

				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					xl={24}
					xxl={24}
					className="survey-list-wrapper"
				>
					<Card
						title="Evaluation List"
						className="card-survey-list"
						extra={
							<Flex>
								{surveyPendingCount > 0 && (
									<Badge
										count={surveyPendingCount ? surveyPendingCount.length : 0}
										overflowCount={99}
									>
										<Button
											type="link"
											className="w-auto h-auto p-0"
											onClick={() => navigate("/evaluation/pending")}
											icon={
												<FontAwesomeIcon
													icon={faClipboardListCheck}
													style={{
														color: "#2d60e2",
													}}
												/>
											}
										/>
									</Badge>
								)}
							</Flex>
						}
					>
						<SurveyList />
					</Card>
				</Col>
			</Row>
		</DashboardContext.Provider>
	);
}
