import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, List, Typography } from "antd";

import DashboardContext from "./DashboardContext";
import { role } from "../../../providers/companyInfo";

export default function SurveyList() {
	const { dataSource } = useContext(DashboardContext);

	const navigate = useNavigate();

	if (dataSource && dataSource.faculties && dataSource.faculties.length) {
		return (
			<List
				bordered
				dataSource={dataSource.faculties}
				renderItem={(item, index) => {
					return (
						<List.Item
							className={
								index !== dataSource.faculties.length - 1 ? "mb-10" : ""
							}
						>
							<div className="wrapper-content">
								<div className="content">
									{role() === "Student" ? (
										<>
											<Typography.Text>
												{item.faculty_name ? item.faculty_name : "No Faculty"}
											</Typography.Text>
											<Typography.Text> - </Typography.Text>
											<Typography.Text>
												{item.subject ? item.subject : "No Subject"}
											</Typography.Text>
										</>
									) : (
										<Typography.Text>{item.fullname}</Typography.Text>
									)}
								</div>

								<div className="action">
									<Button
										className="btn-add-tertiary"
										disabled={
											role() === "Student" ? !item.faculty_name : !item.fullname
										}
										onClick={async () => {
											if (role() === "Student") {
												if (item.faculty_name) {
													navigate(`/evaluation/${item.id}`);
												}
											} else {
												if (item.fullname) {
													navigate(`/evaluation/${item.id}`);
												}
											}
										}}
									>
										Take Evaluation
									</Button>
								</div>
							</div>
						</List.Item>
					);
				}}
			/>
		);
	} else {
		return (
			<div className="div-no-survey-list">
				<div className="content">No Evaluation Available</div>
			</div>
		);
	}
}
